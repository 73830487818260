import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../core/services/api.service";
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HeaderService } from 'src/app/core/services/header.service';

@Component({
  selector: 'app-advisor',
  templateUrl: './advisor.component.html',
  styleUrls: ['./advisor.component.scss']
})
export class AdvisorComponent implements OnInit {
  userDetails: any = [];
  billingTeamBumber: any = "";
  subscription_plans: any = [];
  loader: boolean = false;
  loaderNew: boolean = false;
  isModelStatus: boolean = false;
  formData: any = {};
  subscriptionformData: any = {
    reference: '6',
    subscription_plan: '',
    amount_type: 'amount',
    paidamount: '',
    comments: '',
    razorpay_id: ''
  };
  advisorNameID: any = "";

  subscriptionformError: any = {
    reference: '',
    subscription_plan: '',
    amount_type: '',
    paidamount: '',
    comments: '',
    razorpay_id: ''
  };
  message: string = "";
  formError: string = "";
  amount: number;
  adminResponse: any = {};
  notificationStatus: string = "";
  notificationStatusError: string = "";
  selectedSubscription: any = null;

  constructor(private apiService: ApiService, private authService: AuthService, private router: Router, private http: HttpClient, private headerService: HeaderService) { }

  ngOnInit() {
    this.getAdviser();
    this.notificationStatus = "";
  }

  getAdviser = () => {
    this.loader = true;
    this.apiService
      .callGetAPI("v2/customer/subscription", environment.userApiUrl)
      .subscribe(
        (data) => {
          this.loader = false;
          if (data['response'].is_adviser != 1) {
            this.router.navigate(['404']);
            return false;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  getUserDetails = (knid: number) => {
    this.notificationStatusError = "";
    this.notificationStatus = "";
    this.loader = true;
    this.apiService
      .callGetAPI("v2/customer/details/" + knid, environment.userApiUrl)
      .subscribe(
        (data) => {
          this.loader = false;
          if (data['response']) {
            this.userDetails = data['response'];
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  onSubmit() {

    if (this.formData.knid == undefined || this.formData.knid == "") {
      this.formError = "";
      this.userDetails = {};
    } else {
      this.formError = "";
      this.getUserDetails(this.formData.knid);
    }
  }


  checkData = data => {
    //console.log(data);
    return 'test';
  }

  startConversation(campaignName: any, e: any, btn: any) {
    this.notificationStatus = "";
    let notificationStatus = "";
    let templateParam = [];
    let userData: any = {};
    let advisorName = "";
    const userValues = localStorage.getItem('currentUser');
    if (userValues) {
      userData = this.authService.decryption(userValues);
      advisorName = userData.response.name;
    }
    if (btn == 1) {
      templateParam = [e.name, String(e.id), advisorName];
      notificationStatus = 'Start conversation message sent successfully';
    } else if (btn == 2) {
      notificationStatus = 'Missed call message sent successfully';
      templateParam = [e.name, e.name, String(e.id), advisorName];
    } else if (btn == 3) {
      templateParam = [e.plan, String(e.id), advisorName, e.details];
      notificationStatus = '';
    } else if (btn == 4) {
      templateParam = [e.plan, e.plan];
      notificationStatus = 'User subscribed successfully';
      // console.log(templateParam)
    }
    const apiUrl = 'v2/send-whatsapp';
    this.loader = true;
    this.apiService
      .callPostAPI(apiUrl,
        {
          'campaignName': campaignName,
          'destination': String(e.phone),
          'templateParams': templateParam,
        }, environment.userApiUrl)
      .subscribe(
        (data) => {
          this.loader = false;
          if (data['statusCode'] == 200) {
            this.notificationStatus = notificationStatus;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }

      );
  }

  getPlanList() {


    // this.loader = true;
    this.apiService
      .callGetAPI("v2/admin-subscription", environment.userApiUrl)
      .subscribe(
        (data) => {
          // this.loader = false;
          if (data['response']) {
            //console.log(data['response'])
            this.subscription_plans = data['response'];
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }



  adminLogin() {
    this.notificationStatus = "";
    this.notificationStatusError = "";
    this.getPlanList();
  }

  getBillingTeamBumber() {
    this.loader = true;
    this.apiService
      .callGetAPI("v2/static-data/billing_team_number", environment.userApiUrl)
      .subscribe(
        (data) => {
          this.loader = false;
          if (data['response']) {
            this.billingTeamBumber = data['response'];
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  isModel(e: any) {

    if (e) {

      this.adminLogin()
    }
    this.isModelStatus = !this.isModelStatus;
  }

  genrateCurrentDate() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0'); // Ensures 2 digits
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const year = now.getFullYear();
    return `${day}-${month}-${year}`;
  }

  clearFormData() {
    this.subscriptionformData = {
      reference: '6',
      subscription_plan: '',
      amount_type: 'amount',
      paidamount: '',
      comments: '',
    };
  }

  fields = [
    { field: 'subscription_plan', message: 'Please select subscription plan' },
    { field: 'amount_type', message: 'Please select amount type' },
    { field: 'paidamount', message: 'Please select amount' },
    { field: 'comments', message: 'Please enter comments' },
    { field: 'reference', message: 'Please select reference' },
    { field: 'razorpay_id', message: 'Please enter razorpay id' }
  ];

  customerSubscription() {

    this.getBillingTeamBumber();
    this.subscriptionformData.start_date = this.genrateCurrentDate();
    this.subscriptionformData.id = this.userDetails.id;
    this.subscriptionformData.name = this.userDetails.name;
    this.subscriptionformData.email = this.userDetails.email;
    this.subscriptionformData.phone = this.userDetails.phone;
    this.subscriptionformData.is_invoice = true;
    let hasError = false;
    this.fields.forEach(({ field, message }) => {
      if (this.subscriptionformData[field] === '') {
        this.subscriptionformError[field] = message;
        hasError = true;
      } else {
        this.subscriptionformError[field] = '';
      }
    });
    if (hasError) {
      return;
    }

    this.subscriptionformData.amount = this.selectedSubscription.amount;
    let remainingAmount = 0;
    if (this.selectedSubscription.amount > this.subscriptionformData.paidamount) {
      remainingAmount = this.selectedSubscription.amount - this.subscriptionformData.paidamount;
    }
    let userData: any = {};
    const userValues = localStorage.getItem('currentUser');

    if (userValues) {
      userData = this.authService.decryption(userValues);
      this.advisorNameID = userData.response.id + ' - ' + userData.response.name;

    }

    this.subscriptionformData.comments =
      `Paid amount - ${this.subscriptionformData.paidamount}, Razorpay id - ${this.subscriptionformData.razorpay_id}, Remaining amount - ${remainingAmount} , Advisor : ${this.advisorNameID}, ${this.subscriptionformData.comments}`;

    const teplateDetails2 = {
      "plan": this.selectedSubscription.name,
      "phone": "91" + this.userDetails.phone    //user number
    }

    // console.log(this.subscriptionformData);
    // return;
    this.isModelStatus = false;
    const apiUrl = 'v2/admin-subscription';
    this.loaderNew = true;
    this.apiService
      .callPostAPI(apiUrl, this.subscriptionformData, environment.userApiUrl)
      .subscribe(
        (data: any) => {
          if (data.status == "error") {
            this.loaderNew = false;
            this.clearFormData()
            this.notificationStatusError = data.message;
          } else {
            const phoneNumbers = this.billingTeamBumber.split(',');
            phoneNumbers.forEach((number: any) => {
              const teplateDetails1 = {
                "plan": this.selectedSubscription.id + ' - ' + this.selectedSubscription.name,
                'id': this.userDetails.id,
                "details": this.subscriptionformData.comments,
                "phone": number //billing team number 
              }
              //console.log(teplateDetails1)
              this.startConversation('kn_subs_activationinfo_forbillingteam', teplateDetails1, 3)
            });
            this.startConversation('kn_subs_activation_1_campaign', teplateDetails2, 4)
            this.message = '';
            this.clearFormData()

            this.loaderNew = false;
            setTimeout(() => {
              this.message = '';
            }, 7000);
          }

        },
        (error) => {
          console.log('error', error)
          this.loaderNew = false;
          this.clearFormData()
          this.notificationStatusError = error.error.message;
          this.apiService.failureResponse(error, true);
        }

      );
  }

  onSubscriptionChange(event: any) {
    const selectedPlanId = this.subscriptionformData.subscription_plan;
    this.selectedSubscription = this.subscription_plans.find(subscription => subscription.id === +selectedPlanId);
  }





}
