import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { UserService } from '../../../core/services/user.service';
import { AuthService } from '../../../core/auth/auth.service';
import { ApiService } from '../../../core/services/api.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { VideoShareService } from '../../../core/services/video-share.service';
import { PlaylistAddService } from '../../../core/services/playlist-add.service';
import { UtilityService } from '../../../core/services/utility.service';
import { environment } from '../../../../environments/environment';
import { Meta } from '@angular/platform-browser';
import { Subject } from 'rxjs/Subject';
import { FormGroup, FormControl, Validators, FormGroupDirective } from '@angular/forms';
import disableScroll from 'disable-scroll';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import { TranslateService } from '@ngx-translate/core';
import { HeaderService } from 'src/app/core/services/header.service';
declare var $: any;
declare var FB: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showAnalytic: Subject<boolean> = new Subject();
  tellChild() {
    this.showAnalytic.next(true);
  }
  theme: any;
  user: any = [];
  search: any = [];
  searchText: any;
  signIn = false;
  signUp = false;
  forget = false;
  category: any = [];
  categoryLength: any = [];
  searchData: any = [];
  searchString: string;
  dropdown: any;
  searchDropdown: any;
  menuClick = false;
  notificationDropdown: any;
  notificationClick = false;
  searchClick = false;
  profileClick = false;
  profileDropdown: any;
  addOpenClass = false;
  addOpenProfile = false;
  addOpenSearch = false;
  shareFlag: any = false;
  searchFlag: any = 0;
  videoDetails: any = {};
  audioDetails: any = {};
  playlist: any = [];
  j: any = 0;
  playlist_name: any;
  result: any;
  success: any;
  submitted: boolean;
  buttonText: String;
  errors: any;
  resultCount: any = 0;
  index: any = -1;
  page: any = 1;
  final: Boolean = false;
  flag: Boolean;
  loading: Boolean;
  searchBox: any;
  searchBoxClick = false;
  playlistForm: FormGroup;
  sharePopupTarget: any;
  firstTimeUser = localStorage.getItem('firstTimeUser');
  routeSub: any;
  userValues: any;
  is_subscribed: any = 0;
  searchContnet = new FormControl();
  formCtrlSub: any;
  searchValue: any;
  playlistSubmitted: any = false;
  link: string;
  login_type: Boolean = true;
  bodyVar: any;
  routerUrlSlug;
  adminPlaylistData: any = {};
  webSeriesData: any = {};
  currentRoute;
  subCategory: any;
  moreCategories: any;
  catIndex: any;
  menues: any = [];
  chartTitle: any;
  yesterday: any;
  today: any;
  restrictSubscriptionData: any = {};

  public languageList = [{ lang: 'en', name: 'English - EN', alis: 'EN' },
  { lang: 'hi', name: 'हिंदी - HI', alis: 'HI' }
    // { lang: 'fr', name: 'Français - FR', alis: 'FR' }
  ];
  public selectedLang = [];
  public showLangDrop = false;
  showLangDropdown: any;
  siteLang: any;
  shareUrlSlug: any = 'video/';

  public liveroute: Boolean = false;
  banner = [];
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.notificationDropdown !== undefined && this.notificationClick &&
      (!document.getElementById('notification-dropdown').contains(event.target))) {
      const element = document.getElementById('notify');
      if (!element.contains(event.target)) {
        this.notificationClick = false;
      }
    }

    const ev = event.target;
    if (ev.closest('.notify-list') && ev.className !== 'notify-more-ic' && ev.className !== 'notify-more' && ev.className !== 'notify-more' && ev.className !== 'remove-tooltip' && !ev.closest('notify-more-ic') && !ev.className.includes('read_more')) {
      this.notificationClick = false;
    }

    if (ev.className.includes('read_more')) {
      const parent = ev.closest('.notify-content');
      if (ev.className.includes('show-more')) {
        parent.children[1].style.display = 'none';
        parent.children[2].style.display = 'block';
      } else if (ev.className.includes('show-less')) {
        parent.children[2].style.display = 'none';
        parent.children[1].style.display = 'block';
      }
    }

    if (this.showLangDropdown !== undefined && this.showLangDrop &&
      (!document.getElementById('lang-dropdown').contains(event.target))) {
      const element = document.getElementById('lang-toggle');
      if (!element.contains(event.target)) {
        this.showLangDrop = false;
      }
    }
    // $('.min-content,.searchboxEvent').click(function(this) {
    //   if (this.id !== 'video-search') {
    //     $('.search-blk').removeClass('open');
    //   }
    // });
    if (this.searchBox !== undefined && this.searchBoxClick) {
      const element = document.getElementById('search');
      if (element.contains(event.target)
      ) {
        $('.search-blk').addClass('open');
        setTimeout(() => {
          document.getElementById('video-search').focus();
        }, 700);
      } else if (!element.contains(event.target) && !this.router.url.startsWith('/search')) {
        $('.search-blk').removeClass('open');
        this.searchBoxClick = false;
      }
    }

    if (this.searchDropdown !== undefined && this.searchClick) {
      if (this.eRef.nativeElement.contains(event.target) && !(this.searchDropdown.contains(event.target))
        || !this.eRef.nativeElement.contains(event.target)) {
        this.search = [];
        this.searchData = [1, 2, 3];
        this.searchText = 1;
      } else if (this.searchDropdown.contains(event.target)) {
        this.videoSearch(this.searchString);
      }
    }

    if (this.profileDropdown !== undefined && this.profileClick) {
      if (this.eRef.nativeElement.contains(event.target) && !(this.profileDropdown.contains(event.target))
        || !this.eRef.nativeElement.contains(event.target)) {
        $('.dropdown-profile').removeClass('open');
      } else if (this.profileDropdown.contains(event.target)) {
        $('.dropdown-profile').toggleClass('open');
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollPosY = window.pageYOffset || document.body.scrollTop;
    const navBar = document.querySelector('.f-head');
    const wholeHeader = document.querySelector('.whole-header');
    const searchBox = document.querySelector('.search-results');
    if (scrollPosY > 80) {
      navBar.classList.add('fixed');
      wholeHeader.classList.add('header-fixed');
      if (searchBox) {
        searchBox.classList.add('shadow');
      }
    } else {
      wholeHeader.classList.remove('header-fixed');
      navBar.classList.remove('fixed');
      if (searchBox) {
        searchBox.classList.remove('shadow');
      }
    }
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private apiService: ApiService,
    private eRef: ElementRef,
    private videoShareService: VideoShareService,
    private playlistAddSearvuce: PlaylistAddService,
    private utilityService: UtilityService,
    private meta: Meta,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private headerService: HeaderService
  ) {
  }

  ngOnInit() {
    sessionStorage.setItem('show_analytics', '1');
    this.checkurl(this.router.url.split('/')[1].split('?')[0]);
    if (localStorage.getItem('currentLanguage')) {
      this.translate.use(localStorage.getItem('currentLanguage'));
    }
    this.translate.get('HEADER.ADD_PLAYLIST').subscribe(res => {
      this.buttonText = res;
    });
    this.formCtrlSub = this.searchContnet.valueChanges
      .debounceTime(500).distinctUntilChanged()
      .subscribe((newValue) => {
        this.serachChange();
      });
    this.userValues = localStorage.getItem('currentUser');
    this.getIsSubscribed();
    this.routeSub = this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.checkurl(this.router.url.split('/')[1].split('?')[0]);
        this.currentRoute = this.router.url;
        this.userValues = localStorage.getItem('currentUser');
        if (this.userValues) {
          this.getIsSubscribed();
        }
        if (!this.router.url.startsWith('/search')) {
          this.searchValue = '';
          this.routerUrlSlug = this.router.url.split('/');
          this.routerUrlSlug = this.routerUrlSlug[this.routerUrlSlug.length - 1];
        } else {
          this.route.queryParams.subscribe(params => {
            this.searchValue = params['q'];
          });
        }
      }
      this.searchFlag = 0;
      if ((window.location.pathname === '/home' || window.location.pathname === '/') && window.screen.width >= 800) {
        document.getElementsByTagName('header')[0].classList.add('home-header');
        if (!localStorage.getItem('firstTimeUser')) {
          document.getElementsByTagName('body')[0].classList.add('home-demo-content');
        }
      } else {
        if (document.getElementsByTagName('header') && document.getElementsByTagName('header').length > 0) {
          document.getElementsByTagName('header')[0].classList.remove('home-header');
        }
        if (!localStorage.getItem('firstTimeUser')) {
          document.getElementsByTagName('body')[0].classList.remove('home-demo-content');
        }
      }

    });

    if (localStorage.getItem('currentUser') !== null || localStorage.getItem('currentUser') !== undefined) {
      this.userValues = localStorage.getItem('currentUser');
    }
    this.routerUrlSlug = this.router.url.split('/');
    this.routerUrlSlug = this.routerUrlSlug[this.routerUrlSlug.length - 1];



    if (localStorage.getItem('currentUser')) {
      this.getTermsAndCnd();
    }
    this.bodyVar = document.getElementsByTagName('body')[0];
    if (this.firstTimeUser === undefined || this.firstTimeUser === null) {
      this.bodyVar.classList.add('firstuser-live');
    }
    this.selectedLang = this.languageList.map(_ => false);
    this.languageList.forEach((val, i) => {
      if (localStorage.getItem('currentLanguage') === val.lang) {
        this.selectedLang[i] = true;
        this.siteLang = this.languageList[this.selectedLang.indexOf(true)].alis;
      }
      // if(this.selectedLang.indexOf(true) > -1){
      //   this.siteLang = this.languageList[this.selectedLang.indexOf(true)].alis;
      // } else {
      //   this.siteLang = null;
      // }
    });
    this.headerService.bannerData$.subscribe(data => {
      this.banner = data;
    });
    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === 'light') {
        this.translate.get('HEADER.DARK').subscribe(res => {
          this.theme = res;
        });
        document.getElementsByClassName('dark-icon')[0].classList.remove('activated');
        document.body.classList.add('main-light');
        if (this.banner === null) {
          document.body.classList.add('no-banner');
        }
      }
      if (localStorage.getItem('theme') === 'dark') {
        this.translate.get('HEADER.LIGHT').subscribe(res => {
          this.theme = res;
        });
        document.getElementsByClassName('dark-icon')[0].classList.add('activated');
        document.body.classList.add('main-dark');
      }
    } else {
      this.translate.get('HEADER.DARK').subscribe(res => {
        this.theme = res;
      });
      document.body.classList.add('main-dark');
      localStorage.setItem('theme', 'dark');
    }

    if (localStorage.getItem('firstTimeUser') === undefined || localStorage.getItem('firstTimeUser') === null) {
      document.getElementsByTagName('body')[0].classList.add('first-user-activated');
    }

    this.playlistForm = new FormGroup({
      playlist_name: new FormControl('', [Validators.required])
    });
    // user details Observable
    this.userService.localUser.subscribe(
      data => {
        const currentUser = this.authService.decryption(data);
        this.user = (currentUser) ? currentUser['response'] : '';
        if (this.user) {
          this.translate.get('ANALYTICS.VIDEO_WATCHED').subscribe(async res => {
            this.chartTitle = await res;
          });
          this.translate.get('ANALYTICS.YESTERDAY').subscribe(async res => {
            this.yesterday = await res;
          });
          this.translate.get('ANALYTICS.TODAY').subscribe(async res => {
            this.today = await res;
          });
          this.login_type = currentUser['response'].login_type === 'Web' ? true : false;
        }
      });
    // Popup details Observable
    this.userService.display.subscribe(
      data => {
        this.onShowHide(data);
      });
    // popup for video share
    this.videoShareService.display.subscribe(
      data => {
        this.showSharePopup(data);
        this.j++;
      }
    );
    // popup for add playlist
    this.playlistAddSearvuce.display.subscribe(data => {
      if (localStorage.getItem('currentUser')) {
        this.showPlaylistPopup(data);
      } else if (this.j > 1) {
        this.signIn = true;
      }
      this.j++;
    });
    this.videoShareService.obtainAdminPlaylistShareData.subscribe(
      data => {
        this.displayAdminPlaylistSharePopup(data);
      });

    this.videoShareService.obtainWebSeriesShareData.subscribe(
      data => {
        this.displayWebSeriesSharePopup(data);
      });

    this.videoShareService.obtainRestrictVideoPlay.subscribe(
      data => {
        this.restrictVideoPlayPopup(data);
      });
    this.videoShareService.obtainRestrictVideoPlayGeoIP.subscribe(
      data => {
        this.restrictVideoPlayPopupGeoIP(data);
      });
    this.videoShareService.obtainRestrictSubscription.subscribe(
      data => {
        this.restrictSubscriptionPopup(data);
      });
    this.shareFlag = false;
    //
    this.getCategoryList();




    // toggle dropdown
    const toggleMenu = document.querySelector('.toggle-menu');
    const dropMenu = document.querySelector('.dropdown-menu');


    // search bar
    if (screen.width <= 1024) {
      const searchBox = document.querySelector('.search-blk');
      const bodyClick = document.querySelector('body');

      // searchBox.addEventListener('click', function(e) {
      //   searchBox.classList.add('open');
      // });
      bodyClick.addEventListener('click', function (e) {
        if ((e.target['parentNode'].className).indexOf('search-blk') === -1) {
          searchBox.classList.remove('open');
        }
      });
    }


    // mobile menu
    if (screen.width <= 800) {
      dropMenu.classList.add('mobile-menu');
      toggleMenu.addEventListener('click', function () {
      });
    }
    // fixed header
    window.addEventListener('scroll', function () {
      const scrollPosY = window.pageYOffset || document.body.scrollTop;
      const navBar = document.querySelector('.f-head');
      const wholeHeader = document.querySelector('.whole-header');
      const searchBox = document.querySelector('.search-results');
      if (window.pageYOffset > 80) {
        navBar.classList.add('fixed');
        wholeHeader.classList.add('header-fixed');
        if (searchBox) {
          searchBox.classList.add('shadow');
        }
      } else {
        navBar.classList.remove('fixed');
        wholeHeader.classList.remove('header-fixed');
        if (searchBox) {
          searchBox.classList.remove('shadow');
        }
      }
    });
    window.addEventListener('keyup', function (event) {
      if (event.keyCode === 27) {
        this.closeSharePopup();
        this.hidePlaylistPopup();
        this.cancelToAddPlaylist();
        this.cancelToGenAddPlaylist();
        this.hideRestrictVideoPlayPopup();
        this.hideRestrictVideoPlayPopupGeoIP();
        this.hideRestrictSubscriptionPopup();
        this.signIn = false;
        this.forget = false;
        this.signUp = false;
      }
    }.bind(this));

    /*if (this.playlist.length > 0) {
      const elementOfPopup = document.getElementsByClassName('create-playlist-list');
      elementOfPopup[0].addEventListener('scroll', function () {
        if (elementOfPopup[0].scrollHeight - elementOfPopup[0].scrollTop < 250 &&
          this.final === false && this.flag === false) {
          this.page += 1;
          this.loading = true;
          this.flag = true;
        }
      }.bind(this));
    }*/
  }
  toggleTheme(): void {

    if (document.body.classList.contains('main-dark')) {
      localStorage.setItem('theme', 'light');
      document.body.classList.remove('main-dark');
      document.body.classList.add('main-light');
      if (this.banner['data'].length === 0) {
        document.body.classList.add('no-banner');
      } else {
        document.body.classList.remove('no-banner');
      }
      document.getElementsByClassName('dark-icon')[0].classList.remove('activated');
      this.translate.get('HEADER.DARK').subscribe(res => {
        this.theme = res;
      });
    } else if (document.body.classList.contains('main-light')) {
      localStorage.setItem('theme', 'dark');
      document.body.classList.remove('main-light');
      document.body.classList.add('main-dark');
      document.getElementsByClassName('dark-icon')[0].classList.add('activated');
      this.translate.get('HEADER.LIGHT').subscribe(res => {
        this.theme = res;
      });
      document.body.classList.remove('no-banner');

    } else {
      if (localStorage.getItem('theme')) {
        if (localStorage.getItem('theme') === 'light') {
          this.translate.get('HEADER.DARK').subscribe(res => {
            this.theme = res;
          });
          document.body.classList.add('main-light');
        }
        if (localStorage.getItem('theme') === 'dark') {
          this.translate.get('HEADER.LIGHT').subscribe(res => {
            this.theme = res;
          });
          document.body.classList.add('main-dark');
        }
      } else {
        this.translate.get('HEADER.DARK').subscribe(res => {
          this.theme = res;
        });
        document.body.classList.add('main-light');
      }
    }
  }
  serachChange() {
    if (!this.router.url.startsWith('/search')) {
      if (this.router.url) {
        localStorage.setItem('currentUrl', this.router.url);
      }
    }
    if (this.searchValue === undefined || this.searchValue === null || this.searchValue.trim().length < 1) {
      if (localStorage.getItem('currentUrl')) {
        // this.router.navigate([localStorage.getItem('currentUrl')]);
        // localStorage.removeItem('currentUrl');
      } else {
        this.router.navigate(['home']);
      }
    } else {
      this.router.navigate(['/search'], { queryParams: { q: this.searchValue } });
    }
  }
  showNotifications(event) {
    this.notificationDropdown = event.target;
    this.notificationClick = !this.notificationClick;
    this.apiService.callGetAPI('v2/notification/clear', environment.notificationApiUrl).subscribe(data => {
      this.user.notification_count = 0;
    }, error => {
      this.apiService.failureResponse(error);
    });
  }
  showLangDropMethod(e) {
    this.showLangDropdown = e;
    this.showLangDrop = !this.showLangDrop;
  }
  // open share popup
  showSharePopup(data: any) {
    if (data && this.j > 1) {
      disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
      this.videoDetails = data;
      this.shareUrlSlug = (this.videoDetails.hasOwnProperty('iswebseries') && this.videoDetails.iswebseries === true) ? 'webseries/' : 'video/';
      if (this.videoDetails && !this.videoDetails.thumbnail_image) {
        this.videoDetails.thumbnail_image = 'assets/images/blank_new.png';
      }
      this.meta.updateTag({ property: 'og:title', content: this.videoDetails.title });
      this.meta.updateTag({ property: 'og:image', content: this.videoDetails.thumbnail_image });
      if (this.videoDetails.description) {
        this.meta.updateTag({ property: 'og:description', content: this.videoDetails.description });
      }
      this.meta.updateTag({ property: 'og:url', content: `${environment.domain + this.shareUrlSlug + this.videoDetails.slug}` });
      const elements = document.getElementsByClassName('share-popup');
      elements[0].classList.remove('close');
      elements[0].classList.add('open');
      const element = document.getElementsByTagName('body');
      element[0].classList.add('modal-popup');
      element[0].classList.add('for-video');
      this.shareFlag = true;
    }
  }
  displayAdminPlaylistSharePopup(data: any) {
    if (data && typeof data === 'object') {
      this.adminPlaylistData = data;
      if (!this.adminPlaylistData.playlist_image && typeof data === 'object') {
        this.adminPlaylistData.playlist_image = 'assets/images/blank_new.png';
      }
      this.meta.updateTag({ property: 'og:title', content: this.adminPlaylistData.name });
      this.meta.updateTag({ property: 'og:image', content: this.adminPlaylistData.playlist_image });
      if (this.adminPlaylistData.description) {
        this.meta.updateTag({ property: 'og:description', content: this.adminPlaylistData.description });
      }
      this.meta.updateTag({ property: 'og:url', content: `${environment.domain + 'playlist/' + this.adminPlaylistData.slug}` });
      const elements = document.getElementsByClassName('admin-playlist-share-popup');
      elements[0].classList.remove('close');
      elements[0].classList.add('open');
      const element = document.getElementsByTagName('body');
      element[0].classList.add('modal-popup');
      element[0].classList.add('for-video');
      this.shareFlag = true;
    }
  }
  displayWebSeriesSharePopup(data: any) {
    if (data && typeof data === 'object') {
      this.webSeriesData = data;
      if (!this.webSeriesData.thumbnail_image && typeof data === 'object') {
        this.webSeriesData.thumbnail_image = 'assets/images/blank_new.png';
      }
      this.meta.updateTag({ property: 'og:title', content: this.webSeriesData.title });
      this.meta.updateTag({ property: 'og:image', content: this.webSeriesData.thumbnail_image });
      if (this.webSeriesData.description) {
        this.meta.updateTag({ property: 'og:description', content: this.webSeriesData.description });
      }
      this.meta.updateTag({ property: 'og:url', content: `${environment.domain + 'webseries/' + this.webSeriesData.slug}` });
      const elements = document.getElementsByClassName('web-series-share-popup');
      elements[0].classList.remove('close');
      elements[0].classList.add('open');
      const element = document.getElementsByTagName('body');
      element[0].classList.add('modal-popup');
      element[0].classList.add('for-video');
      this.shareFlag = true;
    }
  }
  restrictVideoPlayPopup(data: any) {
    if (data && typeof data === 'object') {
      disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
      this.loading = true;
      const elements = document.getElementsByClassName('restrict-video-popup');
      elements[0].classList.remove('close');
      elements[0].classList.add('open');
      const element = document.getElementsByTagName('body');
      element[0].classList.add('modal-popup');
      element[0].classList.add('for-video');
      this.shareFlag = true;
    }
  }
  hideRestrictVideoPlayPopup() {
    disableScroll.off();
    //this.playlist = [];
    this.videoShareService.setWebSeriesShareData('close');
    const elements = document.getElementsByClassName('restrict-video-popup');
    elements[0].classList.remove('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.remove('modal-popup');
    element[0].classList.remove('for-video');
  }
  restrictVideoPlayPopupGeoIP(data: any) {
    if (data && typeof data === 'object') {
      disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
      this.loading = true;
      const elements = document.getElementsByClassName('restrict-video-popup-geoip');
      elements[0].classList.remove('close');
      elements[0].classList.add('open');
      const element = document.getElementsByTagName('body');
      element[0].classList.add('modal-popup');
      element[0].classList.add('for-video-geoip');
      this.shareFlag = true;
    }
  }
  hideRestrictVideoPlayPopupGeoIP() {
    disableScroll.off();
    //this.playlist = [];
    this.videoShareService.setWebSeriesShareData('close');
    const elements = document.getElementsByClassName('restrict-video-popup-geoip');
    elements[0].classList.remove('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.remove('modal-popup');
    element[0].classList.remove('for-video-geoip');
  }

  restrictSubscriptionPopup(data: any) {
    if (data && data == 1 || data == 2) {
      this.restrictSubscriptionData = { sub_dailog: data };
      disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
      this.loading = true;
      const elements = document.getElementsByClassName('restrict-subscription-popup');
      elements[0].classList.remove('close');
      elements[0].classList.add('open');
      const element = document.getElementsByTagName('body');
      element[0].classList.add('modal-popup');
      element[0].classList.add('for-restrict-subscription');
      this.shareFlag = true;
    }
  }
  hideRestrictSubscriptionPopup() {
    disableScroll.off();
    //this.playlist = [];
    this.videoShareService.setWebSeriesShareData('close');
    const elements = document.getElementsByClassName('restrict-subscription-popup');
    elements[0].classList.remove('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.remove('modal-popup');
    element[0].classList.remove('for-restrict-subscription');
  }
  // open add video to playlist popup
  showPlaylistPopup(data) {
    console.log('data playlist', data);
    if (typeof data === 'object' && data !== null && (data.hasOwnProperty('album_name') || data.hasOwnProperty('audio_title'))) {
      console.log('here', data);
      this.audioDetails = data;
      this.videoDetails = null;
      var element = document.getElementsByTagName('body');
      element[0].classList.add('for-audio');
      this.getPlaylists(true);
    } else {
      if (data && this.j > 1) {
        this.videoDetails = data;
        this.audioDetails = null;
        this.getPlaylists();
        var element = document.getElementsByTagName('body');
        element[0].classList.add('for-video');
      }
    }
    if (data && this.j > 1) {
      disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
      this.loading = true;
      // get playlist lists
      this.playlist = [];
      this.page = 1;
      this.final = false;
      const elements = document.getElementsByClassName('create-playlist-popup');
      elements[0].classList.add('open');
      element[0].classList.add('modal-popup');
      this.shareFlag = true;
    }
  }
  hidePlaylistPopup() {
    disableScroll.off();
    this.playlist = [];
    const elements = document.getElementsByClassName('create-playlist-popup');
    elements[0].classList.remove('open');
    const element = document.getElementsByTagName('body');
    element[0].classList.remove('modal-popup');
    element[0].classList.remove('for-video');
  }
  // open add new playlist popup
  addNewPlaylistPopup() {
    disableScroll.off();
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    const elements = document.getElementsByClassName('create-playlist-popup');
    elements[0].classList.remove('open');
    const addPlaylistElement = document.getElementsByClassName('create-playlist-name-popup');
    addPlaylistElement[0].classList.add('open');
    setTimeout(() => {
      document.getElementById('playlist_names').focus();
    }, 500);
  }

  // cancel to add playlist and show playlist popup
  cancelToAddPlaylist() {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    const elements = document.getElementsByClassName('create-playlist-popup');
    elements[0].classList.add('open');
    const addPlaylistElement = document.getElementsByClassName('create-playlist-name-popup');
    addPlaylistElement[0].classList.remove('open');
  }
  cancelToGenAddPlaylist() {
    // disableScroll.off();
    this.playlist_name = '';
    const elements = document.getElementsByClassName('create-playlist-popup');
    elements[0].classList.remove('open');
    const addPlaylistElement = document.getElementsByClassName('create-playlist-name-popup');
    addPlaylistElement[0].classList.remove('open');
  }
  toggleDropdown(_event) {
    this.dropdown = _event.target;
    this.menuClick = true;
  }

  toggleSearchDropdown(_event) {
    this.searchDropdown = _event.target;
    this.searchClick = true;
  }


  toggleProfile(_event) {
    this.profileDropdown = _event.target;
    this.profileClick = true;
  }

  logout() {
    this.authService.logout(true);
    this.is_subscribed = 0;
  }
  closeFirstUser() {
    this.bodyVar.classList.remove('firstuser-live');
    document.getElementsByTagName('body')[0].classList.remove('home-demo-content');
    document.getElementsByTagName('body')[0].classList.remove('first-user-activated');
    localStorage.setItem('firstTimeUser', 'close');
    this.firstTimeUser = localStorage.getItem('firstTimeUser');
  }
  loginRegister(event) {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: false, disableKeys: false });
    this.userService.redirectUrl(this.router.url);
    // if (event.target.className === 'sign-up') {
    //   this.onShowHide('sign-up');
    // } else {
    this.onShowHide('sign-in');
    // }
  }

  // Popup show or hide
  onShowHide(value: string) {
    if (value === 'sign-in') {
      this.signIn = true;
      this.signUp = false;
      this.forget = false;
    } else if (value === 'sign-up') {
      this.signIn = false;
      this.signUp = true;
      this.forget = false;
    } else if (value === 'forget') {
      this.signIn = false;
      this.signUp = false;
      this.forget = true;
    } else {
      this.signIn = false;
      this.signUp = false;
      this.forget = false;
    }
  }

  // Close Share Popup
  closeSharePopup(classname = null) {
    disableScroll.off();
    this.link = undefined;
    const elements = document.getElementsByClassName(classname);
    if (classname === 'admin-playlist-share-popup') {
      this.videoShareService.setAdminPlaylistShareData('close');
    }
    if (classname === 'web-series-share-popup') {
      this.videoShareService.setWebSeriesShareData('close');
    }
    if (classname === 'restrict-video-popup') {
      this.videoShareService.setRestrictVideoPlay('close');
    }
    if (classname === 'restrict-video-popup-geoip') {
      this.videoShareService.setRestrictVideoPlayForGeoIP('close');
    }
    if (classname === 'restrict-subscription-popup') {
      this.videoShareService.setRestrictSubscriptionPopup('close');
    }
    if (elements[0]) {
      elements[0].classList.add('close');
      elements[0].classList.remove('open');
    }
    const element = document.getElementsByTagName('body');
    if (element[0]) {
      element[0].classList.remove('modal-popup');
    }
    this.shareFlag = false;
    const elements2 = document.getElementsByClassName('addnew-card-popup');
    if (elements2[0]) {
      elements2[0].classList.remove('open');
    }
  }
  // Video search
  videoSearch(value: string) {
    const searchValue = document.getElementById('video-search');
    value = searchValue['value'];
    this.searchText = value.length;
    this.searchString = value;
    if (value.length > 0) {
      this.apiService.callGetAPI('search?q=' + value + '&page=1', environment.searchApiUrl)
        .subscribe(
          data => {
            this.searchFlag++;
            setTimeout(() => {
              this.searchData = data['response'].data;
              this.resultCount = data['response'].total;
              this.search = this.searchData.slice(0, 3);
            }, 1000);
          },
          error => {
            this.apiService.failureResponse(error, true);
          });
    } else {
      this.search = [];
    }
  }
  eventHandler(event) {
    if (event.keyCode === 13) {
      if (this.searchValue !== undefined && this.searchValue.length > 1) {
        this.serachChange();
      }
      $('.searchboxEvent').blur();
    }
  }
  // Search video list click
  onVideoClick(video: string) {
    this.search = [];
    const searchValue = document.getElementById('video-search');
    searchValue['value'] = '';
    this.searchText = '';
    this.router.navigate(['video/' + video]);
  }


  // Search video more link click
  onVideoMoreClick() {
    this.search = [];
    const searchValue = document.getElementById('video-search');
    searchValue['value'] = '';
    this.searchText = '';
    this.router.navigate(['/search'], { queryParams: { q: this.searchString } });
  }

  // get catagory list
  getCategoryList() {
    const currentRoute = this.router.url;
    this.headerService.fetchMainmenuCategories()
      .subscribe(
        data => {
          this.categoryLength = data['response'].category_menu.data;
          this.apiService.categories = data['response'].category_menu;
          this.category = this.categoryLength.slice(0, 9);
          this.liveroute = true;
          this.menues = data['response'].enabled_menu;
        },
        error => {
          if (currentRoute === '/' || currentRoute === '/home') {
            // this.headerService.fetchBanner();
          }
        },
        () => {
          if (currentRoute === '/' || currentRoute === '/home') {
            // this.headerService.fetchBanner();
            setTimeout(() => {
              this.getFooterContent();
            }, 2000);
          } else {
            this.getFooterContent();
          }
        });
  }
  getFooterContent() {
    this.headerService.fetchData()
      .subscribe(data => {
        if (this.user) {
          this.user.notification_count = data['response'].notification_count;
        }
        data['category'] = this.categoryLength;
        // set data in service which is to be shared
        this.headerService.setData(data);
      }, error => {
        this.apiService.failureResponse(error);
      });
  }
  // get playlist list
  getPlaylists(audio = false) {
    let url = audio ? 'v2/create_playlist?playlist_type=audio&audio_id=' : 'v2/create_playlist?video_id=';
    let slug = audio ? this.audioDetails.slug : this.videoDetails.slug;
    this.apiService.callGetAPI(url + slug + '&page=' + this.page, environment.userActionApiUrl).subscribe(data => {
      if (data['response'].my_playlist.current_page === 1) {
        this.playlist = data['response'].my_playlist.data;
      } else {
        this.playlist = [...this.playlist, ...data['response'].my_playlist.data];
      }
      if (this.playlist.length > 0) {
        document.getElementById('create_playlist').style.borderBottom = '1px solid #ccc';
      } else {
        document.getElementById('create_playlist').style.borderBottom = '0px solid #ccc';
      }
      if (data['response'].my_playlist.last_page === data['response'].my_playlist.current_page) {
        this.final = true;
      }
      console.log('playlist', this.playlist);
      this.loading = false;
      this.flag = false;
    },
      error => {
        this.apiService.failureResponse(error, true);
      });
  }
  // add video to playlist
  addVideoToPlaylist(playlist_name, playlist_id, i) {

    let url;
    let payload = {};
    if (this.audioDetails) {
      url = 'v2/create_playlist?playlist_type=audio';
      payload = { name: playlist_name, audio: this.audioDetails.id, id: playlist_id };
    } else {
      url = 'v2/create_playlist';
      payload = { name: playlist_name, videos: this.videoDetails.id, id: playlist_id };
    }
    this.apiService.callPostAPI(url, payload, environment.userActionApiUrl).subscribe(data => {
      this.playlist[i].is_added = 1;
      this.apiService.successResponse(data);
    }, error => {
      this.submitted = false;
      this.result = false;
      this.errors = this.apiService.failureResponse(error);
      if (this.errors.commonError) {
        this.result = true;
        this.success = this.errors.commonError;
        this.utilityService.VplyToaster();
      }
    });
  }

  // remove video from playlist
  removeVideoToPlaylist(playlist_id, i) {
    let url;
    if (this.audioDetails) {
      url = 'v2/create_playlist?playlist_type=audio&playlist_id=' + playlist_id + '&audio_id=' + this.audioDetails.slug;
    } else {
      url = 'v2/create_playlist_videos?playlist_id=' + playlist_id + '&video_id=' + this.videoDetails.slug;
    }
    this.apiService.callDeleteAPI(url, environment.userActionApiUrl).subscribe(data => {
      this.playlist[i].is_added = 0;
      this.apiService.successResponse(data);
    }, error => {
      this.submitted = false;
      this.result = false;
      this.errors = this.apiService.failureResponse(error);
      if (this.errors.commonError) {
        this.result = true;
        this.success = this.errors.commonError;
        this.utilityService.VplyToaster();
      }
    });
  }

  updateSubscriptionData(data) {
    this.is_subscribed = data ? 1 : 0;
  }
  // add playlist
  addPlaylist(myForm) {
    if (this.playlist_name.trim() !== undefined && this.playlist_name.trim() !== '') {
      this.playlistSubmitted = true;
      this.translate.get('HEADER.PLEASE_WAIT').subscribe(res => {
        this.buttonText = res;
      });
      this.apiService.callPostAPI(`v2/create_playlist?playlist_type=${this.audioDetails ? 'audio' : 'video'}`, { name: this.playlist_name }, environment.userActionApiUrl).subscribe(data => {
        this.playlistSubmitted = false;
        this.playlistForm.reset();
        myForm.resetForm();
        this.result = false;
        this.apiService.successResponse(data);
        const elements = document.getElementsByClassName('create-playlist-popup');
        elements[0].classList.add('open');
        const addPlaylistElement = document.getElementsByClassName('create-playlist-name-popup');
        addPlaylistElement[0].classList.remove('open');
        this.translate.get('HEADER.ADD_PLAYLIST').subscribe(res => {
          this.buttonText = res;
        });
        this.playlist = [];
        disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
        this.final = false;
        this.flag = true;
        this.page = 1;
        if (this.audioDetails) {
          this.getPlaylists(true);
        } else {
          this.getPlaylists();
        }
      }, error => {
        this.playlistSubmitted = false;
        this.errors = this.apiService.failureResponse(error);
        this.submitted = false;
        this.result = false;
        if (this.errors.commonError) {
          this.result = true;
          this.success = this.errors.commonError;
          this.utilityService.VplyToaster();
          this.translate.get('HEADER.ADD_PLAYLIST').subscribe(res => {
            this.buttonText = res;
          });
        }
      });
    } else {
      this.result = true;
      this.success = 'Playlist name can not be empty';
      this.utilityService.VplyToaster();

    }
  }
  VplyToasterHide() {
    this.utilityService.VplyToasterHide();
  }
  mouseover() {
    const elements = document.getElementsByClassName('dropdown-menu');
    elements[0].classList.add('open');
    const element = document.getElementsByClassName('mobile-overlay');
    element[0].classList.add('open');
  }

  mouseout() {
    const elements = document.getElementsByClassName('dropdown-menu');
    elements[0].classList.remove('open');
    const element = document.getElementsByClassName('mobile-overlay');
    element[0].classList.remove('open');
  }

  mouseoverProfile() {
    this.addOpenProfile = true;
  }

  mouseoutProfile() {
    this.addOpenProfile = false;
  }
  mouseoverCategory(slug, i, j, count) {
    this.subCategory = [];
    const elements = document.getElementsByClassName('menu-for-web');
    // elements[i].classList.add('parent-menu');
    this.subCategory = this.category[i]['child_category'];
    const el = document.querySelectorAll('.new_super-sub-menu');
    for (let k = 0; k < el.length; k++) { document.querySelector('.new_super-sub-menu').classList.remove('active'); }
    document.querySelector(`.current-div-${i}-0`).closest('.new_super-sub-menu').classList.add('active');
    // if (!count) {
    //   document.querySelector('.new_menu').classList.add('no-sub-cat');
    // } else {
    //   document.querySelector('.new_menu').classList.remove('no-sub-cat');
    // }
    this.mouseoverChildCategory(null, i, 0, count);
    // for (let i = 0; i < el.length; i++) {
    //   el[i].classList.remove('active');
    // }
    // document.querySelector(`.current-div-${i}-0`).classList.add('active');
  }
  overlayHover() {
    $('.new_menu li').mouseover(function () {
      $('.min-content').addClass('overlay_min');
    });
    $('.new_menu').mouseout(function () {
      $('.min-content').removeClass('overlay_min');
    });
  }
  mouseoverChildCategory(slug, i, j, count) {
    const el = document.querySelectorAll('.sub_full');
    for (let k = 0; k < el.length; k++) { el[k].closest('.sub_full').classList.remove('active'); }
    if (count) {
      document.querySelector(`.current-sub_full-${i}-${j}`).closest('.sub_full').classList.add('active');
    }
    const el1 = document.querySelectorAll('.new_super-sub-menu');
    for (let l = 0; l < el1.length; l++) {
      // targetElement.closest(selectors);
      el1[l].closest('.new_super-sub-menu').classList.remove('active');
    }
    document.querySelector(`.current-div-${i}-${j}`).closest('.new_super-sub-menu').classList.add('active');
    document.querySelector('.new_menu').classList.remove('col-three', 'col-two', 'col-one');
    if (!count) {
      document.querySelector('.new_menu').classList.add('no-sub-cat');
    } else {
      document.querySelector('.new_menu').classList.remove('no-sub-cat');
      if (count > 14) {
        document.querySelector('.new_menu').classList.add('col-three');
      } else if (count > 7) {
        document.querySelector('.new_menu').classList.add('col-two');
      } else {
        document.querySelector('.new_menu').classList.add('col-one');
      }
    }
    //   const el1 = document.querySelectorAll('.new_super-sub-menu');
    //   for (let i = 0; i < el1.length; i++) {
    //     el1[i].classList.remove('active');
    //   }
    //   console.log('i,j',i,j,e);
    //     document.querySelector(`.current-div-${i}-${j}`).classList.add('active');
  }

  mouseleaveCategory(i) {
    // this.mouseoverChildCategory(null,i,0,1);
  }
  mouseleaveSub(i) {
    const element = document.getElementsByClassName('more-sub-menu');
    // element[i].classList.remove('parent-menu');
  }
  mouseleavemoreCategory() {
    const element = document.getElementsByClassName('hide-menu');
    // element[0].classList.remove('parent-menu');
  }

  OpenSearch(event) {
    this.searchBox = event.target;
    document.getElementById('video-search').focus();
    this.searchBoxClick = this.searchBoxClick ? false : true;
  }


  // facebookShare(this) {
  //   FB.ui(
  //     {
  //       method: 'share',
  //       href: `${environment.apiUrl}v2/video-metadata/${this.videoDetails.slug}`, // Media Service API
  //     },
  //     // callback
  //     function(response) {
  //       // Action after response
  //       if (response && !response.error_message) {
  //         // then get post content
  //         this.apiService.successResponse('successfully posted. Status id : ' + response.post_id);
  //         this.closeSharePopup();
  //       } else {
  //         this.apiService.failureResponse('Something went error.');
  //       }
  //     }
  //   );
  // }

  facebookShare(this, className, data) {
    console.log(this, className, data)
    let url;
    if (className == 'share-popup') {
      url = url = `${environment.apiUrl}v2/fb-share?title=${data.title}&description=${data.description}&image=${data.poster_image}&slug=${data.slug}&type=video`;
    } else if (className == 'admin-playlist-share-popup') {
      url = url = `${environment.apiUrl}v2/fb-share?title=${data.name}&description=&image=${data.playlist_image}&slug=${data.slug}&type=playlist`;
    } else if (className == 'web-series-share-popup') {
      url = url = `${environment.apiUrl}v2/fb-share?title=${data.title}&description=${data.description}&image=${data.poster_image}&slug=${data.slug}&type=series`;
    }
    console.log('url', url)
    FB.ui({
      method: 'feed',
      name: 'Radhe Radhe',
      link: url,
      caption: 'Radhe Radhe',
      description: 'Radhe Radhe description'
    }
      ,
      function (response) {
        // Action after response
        if (response && !response.error_message) {
          // then get post content
          this.apiService.successResponse('successfully posted. Status id : ' + response.post_id);
          this.closeSharePopup(className);
        } else {
          this.apiService.failureResponse('Something went error.');
        }
      });
  }
  twitterShare(className) {
    let twitterWindow;
    if (className != null && className === 'admin-playlist-share-popup') {
      // tslint:disable-next-line:max-line-length
      twitterWindow = window.open('https://twitter.com/intent/tweet?text=' + this.adminPlaylistData.name + '&url=' +
        window.location.protocol + '//' + window.location.host + '/playlist/' + this.adminPlaylistData.slug, 'twitter-popup', 'height=350,width=600');
    } else if (className != null && className === 'web-series-share-popup') {
      twitterWindow = window.open('https://twitter.com/intent/tweet?text=' + this.webSeriesData.title + '&url=' +
        window.location.protocol + '//' + window.location.host + '/webseries/' + this.webSeriesData.slug, 'twitter-popup', 'height=350,width=600');
    } else {
      let path = this.videoDetails.is_story ? 'story-detail/' : 'video/';
      // tslint:disable-next-line:max-line-length
      const twitterWindow = window.open('https://twitter.com/intent/tweet?text=' + this.videoDetails.title + '&url=' +
        window.location.protocol + '//' + window.location.host + '/' + path + this.videoDetails.slug, 'twitter-popup', 'height=350,width=600');
      if (twitterWindow.focus) { twitterWindow.focus(); }
      return false;
    }
  }
  // twitterShare() {
  //   // tslint:disable-next-line:max-line-length
  //   const twitterWindow = window.open('https://twitter.com/intent/tweet?text=' + this.videoDetails.title + '&url=' +
  //     window.location.protocol + '//' + window.location.host + '/video/' + this.videoDetails.slug, 'twitter-popup', 'height=350,width=600');
  //   if (twitterWindow.focus) { twitterWindow.focus(); }
  //   return false;
  // }
  googlePlus() {
    const sharelink = 'https://plus.google.com/share?url=' + environment.domain + 'video/' + this.videoDetails.slug;
    const newwindow = window.open(sharelink, 'name', 'height=400,width=600');
    if (window.focus) {
      newwindow.focus();
    }
    return false;
  }

  updateNotifyCount(event) {
    this.notificationClick = false;
  }
  shareEventTarget(event) {
    this.sharePopupTarget = event.target;
  }
  copyMessage(className) {
    let lang = localStorage.getItem('currentLanguage');
    lang = lang ? lang : 'en';
    const linkToBeCopied =
      className != null && className === 'admin-playlist-share-popup'
        ? environment.apiUrl + 'v2/playlist-metadata/' + this.adminPlaylistData.slug + '/' + lang
        : className != null && className === 'web-series-share-popup'
          ? environment.apiUrl + 'v2/webseries-metadata/' + this.webSeriesData.slug + '/' + lang
          : environment.apiUrl + 'v2/video-metadata/' + this.videoDetails.slug + '/' + lang;
    console.log(linkToBeCopied);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = linkToBeCopied;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let getMessage;
    this.translate.get('HEADER.SHARE_CLIP_MESSAGE').subscribe(res => {
      getMessage = res;
    });
    const message = { error: false, message: getMessage, statusCode: 200 };
    this.apiService.successResponse(message);
    this.closeSharePopup(className)
  }
  embedUrlGenerator() {
    // tslint:disable-next-line:max-line-length
    this.link = '<iframe width="560" height="315" src="' + environment.domain + 'embed/' + this.videoDetails.slug + '" frameborder="0" allowfullscreen></iframe>';
  }
  cancelEmbed() {
    this.link = undefined;
  }
  copyEmbed() {
    disableScroll.on(undefined, { disableWheel: true, disableScroll: true, disableKeys: false });
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = '<iframe width="560" height="315" src="' + environment.domain + 'embed/' + this.videoDetails.slug + '" frameborder="0" allowfullscreen></iframe>';
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    let getMessage;
    this.translate.get('HEADER.SHARE_CLIP_MESSAGE').subscribe(res => {
      getMessage = res;
    });
    const message = { error: false, message: getMessage, statusCode: 200 };
    this.apiService.successResponse(message);
  }

  changeLanguage(i) {
    localStorage.setItem('currentLanguage', this.languageList[i].lang);
    this.translate.use(this.languageList[i].lang);
    this.selectedLang = this.languageList.map(_ => false);
    this.selectedLang[i] = true;
    this.j = 0;
    this.playlist = [];
    this.ngOnInit();
    this.router.navigate(['/loading']);
  }

  getSelectedlang(i) {
    return this.selectedLang[i];
  }

  language(code: any) {
    localStorage.setItem('currentLanguage', this.languageList[code].lang);
    this.translate.use(this.languageList[code].lang);
    this.selectedLang = this.languageList.map(_ => false);
    this.selectedLang[code] = true;
    this.siteLang = this.languageList[this.selectedLang.indexOf(true)].alis;
    this.router.navigate(['/loading']);
  }

  /**
  * Category Navigation
  */
  categoryNavigation(cat) {
    // Navigation based on the web series
    if (cat.is_web_series) {
      if (cat.video_webseries_detail_id !== null) {
        this.router.navigate(['webseries/' + cat.slug]);

      } else {
        this.router.navigate(['series/' + cat.slug]);

      }
    } else {
      this.router.navigate(['category/' + cat.slug]);
    }
  }

  getTermsAndCnd() {
    this.apiService.callGetAPI('v2/auth/get_terms_and_condition', environment.userApiUrl)
      .subscribe(data => {
        const terms_cnd = data['response']['terms_condition'].terms_verified;
        if (!terms_cnd) {
          document.getElementById('myModal').style.display = 'block';
          this.router.navigateByUrl('/profile');
        } else {
          document.getElementById('myModal').style.display = 'none';
        }
      });
  }

  checkedOf(event) {
    if (event.target.checked === true) {
      this.apiService.callPostAPI('v2/auth/save_terms_and_condition', {}, environment.userApiUrl)
        .subscribe(data => {
          document.getElementById('myModal').style.display = 'none';
        });
    }
  }

  menuClicked(e, id) {
    $('.latestnav').removeClass('active');
    $(`#${id}`).addClass('active');
    if (id === 'video') {
      $('.latestnav .sub_new').css({ 'display': 'flex' });
    } else {
      $('.latestnav .sub_new').css({ 'display': 'none' });
    }
    console.log('event', e.target);
  }

  checkMenu(name) {
    return this.menues.includes(name);
  }
  checkurl(url) {
    console.log('url', url);
    let searchField = true;
    let videoUrl = '/home/category/series/video/playlist/webseries/search';
    let audioUrl = '/album-list/albums';
    let story = '/story/story-detail';
    console.log('url', url);
    let menu = false;
    if (videoUrl.includes(url)) {
      $('#video').addClass('active');
      $('.latestnav .sub_new').css({ 'display': 'flex' });
      $('#audio').removeClass('active');
      $('#storys').removeClass('active');
      menu = true;
    } else if (audioUrl.includes(url)) {
      $('#audio').addClass('active');
      $('#video').removeClass('active');
      $('.latestnav .sub_new').css({ 'display': 'none' });
      $('#storys').removeClass('active');
    } else if (story.includes(url)) {
      $('#storys').addClass('active');
      $('#video').removeClass('active');
      $('.latestnav .sub_new').css({ 'display': 'none' });
      $('#audio').removeClass('active');
    } else {
      $('#storys').removeClass('active');
      $('#video').removeClass('active');
      $('.latestnav .sub_new').css({ 'display': 'none' });
      $('#audio').removeClass('active');
    }
    if (menu) {
      (<HTMLElement>document.querySelector('.search-blk')).style.display = 'block';
    } else {
      if (document.querySelector('.search-blk')) {
        (<HTMLElement>document.querySelector('.search-blk')).style.display = 'none';
      }
    }
  }

  showAnalytics() {
    sessionStorage.setItem('show_analytics', '');
    this.showAnalytic.next(true);
  }
  playlistScroll(e) {
    let height = $(".create-playlist-content").height();
    console.log('height', height);
    if (height > 210 && !this.loading && !this.final) {
      if (this.playlist.length > 0) {
        this.page += 1;
        this.loading = true;
        this.flag = true;
        this.getPlaylists();
      }
    }
  }

  getIsSubscribed = () => {

    this.apiService.callGetAPI('v2/customer/subscription', environment.userApiUrl)
      .subscribe(data => {
        console.log('data', data);
        this.is_subscribed = data['response']['is_subscribed'];
        const userValues = localStorage.getItem('currentUser');
        if (userValues) {
          const userData = this.authService.decryption(userValues);
          userData['response'].play_restriction = data['response']['play_restriction'];
          userData['response'].play_restriction_msg = data['response']['play_restriction_msg'];
          const userNewData = this.authService.encryption(userData);
          localStorage.setItem('currentUser', userNewData);
        }
        /* this.play_restriction = data['response']['play_restriction'];
        this.play_restriction_msg = data['response']['play_restriction_msg']; */
      });
  }

  redirectFunction = (playlist, index) => {
    if (playlist.is_added) {
      this.removeVideoToPlaylist(playlist._id, index);
    } else {
      this.addVideoToPlaylist(playlist.name, playlist._id, index);
    }
  }

}
