import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignUpModule } from './modules/sign-up/sign-up.module';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { environment } from '../environments/environment';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { SignInModule } from './modules/sign-in/sign-in.module';
import { ForgetPasswordModule } from './modules/forget-password/forget-password.module';
import { HomeComponent } from './modules/home/home.component';
import { RadioComponent } from './modules/radio/radio.component';
import { StoryComponent } from './modules/story/story.component';
import { AdvisorComponent } from './modules/advisor/advisor.component';
import { SearchComponent } from './modules/search/search.component';
import { ShortsComponent } from './modules/shorts/shorts.component';
import { BannerComponent } from './modules/home/banner/banner.component';
import { OwlModule } from 'ngx-owl-carousel';
import { SharedModule } from './shared/shared.module';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { WatchVideoComponent } from './modules/watch-video/watch-video.component';
import { EmbedComponent } from './modules/embed/embed.component';
import { PlayerComponent } from './modules/video-detail/player/player.component';
import { AdminPlaylistComponent } from './modules/admin-playlist/admin-playlist.component';
import { WebseriesDetailComponent } from './modules/category/webseries-detail/webseries-detail.component';
import { SongComponent } from './modules/audio/song/song.component';
import { AudioComponent } from './modules/audio/audio.component';
import { RefreshComponent } from './modules/refresh/refresh.component';
import { SeeAllStoryComponent } from './modules/see-all-story/see-all-story.component';
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PlyrModule } from 'ngx-plyr';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Ng5SliderModule } from 'ng5-slider';
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.GoogleOAuthClientId)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.FacebookAppId)
  }
]);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    RadioComponent,
    StoryComponent,
    AdvisorComponent,
    SearchComponent,
    ShortsComponent,
    BannerComponent,
    NotificationComponent,
    WatchVideoComponent,
    WebseriesDetailComponent,
    PlayerComponent,
    EmbedComponent,
    AdminPlaylistComponent,
    SongComponent,
    AudioComponent,
    RefreshComponent,
    SeeAllStoryComponent
    // ContusSliderComponent
  ],
  imports: [
    DragDropModule,
    BrowserModule,
    SignUpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    PlyrModule,
    Ng5SliderModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      closeButton: true,
      timeOut: 15000,
      autoDismiss: true,
      positionClass: 'toast-bottom-left',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    SignInModule,
    ForgetPasswordModule,
    SocialLoginModule,
    OwlModule,
    SharedModule,
    LazyLoadImagesModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true })
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
    Title,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    public translate: TranslateService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }
}
