import { Component, OnInit , ElementRef, ViewChild, AfterViewInit , NgZone} from '@angular/core';
import { ApiService } from "../../core/services/api.service";
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HeaderService } from 'src/app/core/services/header.service';
import { AudioService } from 'src/app/core/services/audio.service';
import { UserService } from 'src/app/core/services/user.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'; 
import videojs from '../../../assets/js/play.es.js';
import '../../../assets/js/videojs-playlist.es.js';
import '../../../assets/js/videojs-playlist-ui.es.js'; 
import { PlayerService } from 'src/app/core/services/player.service';
import * as CryptoJS from 'crypto-js'; 
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {
  @ViewChild('chatContainer') private chatContainer: ElementRef;
  loader: boolean = false; 
  page_loader: boolean = false; 
  searchQuery: string = '';
  searchText: string = '';
  sidebar:boolean = true;
  results = []; // Array of chat messages
  historys = []; // Array of chat messages
  currentText = ''; // Holds the current bot message being typed
  typingSpeed = 2050; // Speed in milliseconds for typing effect 
  songIndex: any;
  indexAudio:number = 0;
  jsonToggle:number;
  audioLists = [];

  startTime:number;
  endTime:number;
  sourceURL:string;
  safeSourceURL: SafeResourceUrl;  
  decrypted: any;
  encrypted: any;
  plainText: any;
  cipherText: any;
  interVal: any;
  video: any;
  userAgent: any;
  player: any;
  next_video: any = false;
  subTitle: any = [];
  init_video: any = false;
  init_audio:any = false;

  toggleSidebar:boolean = true;
  indexOptions: any = []; 
  indexMechanism: any = []; 
  indexBatch: any = []; 
  selectedindexValue:string = "basic-kn-index";// Default value
  selectedMechanismValue:string = "similarity_search";
  selectedBatchValue:string = "All Batches";
  value: number = .5;
  options: any = {
    floor: 0,
    ceil: 1,
    step: 0.1, // Step size of 0.1
    showTicks: false, // Show small tick marks
    // showTicksValues: true, // Show numbers as labels
    tickStep: 0.1, // Space between tick labels
    tickValueStep: 0.1 // Show values at every tick
  };

  selectedAudio:any;
  playeraudio: any;
   

  // speechInput = '';
  isRecording = false;
  statusMessage = 'Click the mic to speak';
  recognition: any;
  constructor(private apiService: ApiService, private authService: AuthService, private router: Router, 
    private http: HttpClient, private headerService: HeaderService, private ngZone: NgZone,  
    private audioService: AudioService, private userService: UserService, private sanitizer: DomSanitizer,
    private playerService: PlayerService,private toasterService: ToastrService,) { 
    if (!('webkitSpeechRecognition' in window)) {
      this.statusMessage = 'Speech recognition is not supported on this browser.';
    } else {
      // Initialize recognition with zone for Angular compatibility
      this.recognition = new (window as any).webkitSpeechRecognition();
      this.recognition.continuous = false;
      this.recognition.interimResults = true;
      this.recognition.lang = 'hi-IN';

      this.recognition.onstart = () => {
        this.ngZone.run(() => {
          this.isRecording = true;
          this.statusMessage = 'Listening...';
        });
      };

      this.recognition.onresult = (event: any) => {
        let finalTranscript = '';
      

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript; 
          } 
          // this.searchQuery = finalTranscript;
        }

        this.ngZone.run(() => {
          this.searchQuery += finalTranscript;
          this.statusMessage = finalTranscript ? 'Speech detected!' : 'Listening...';
        });
      };

      this.recognition.onerror = (event: any) => {
        this.ngZone.run(() => {
          this.statusMessage = `Error: ${event.error}`;
          this.stopSpeechRecognition();
        });
      };

      this.recognition.onend = () => {
        this.ngZone.run(() => this.stopSpeechRecognition());
      };
    }
  }
  
  getPathAccess = () => {
    this.page_loader = true;
    this.apiService
      .callGetAPI("v2/customer/subscription", environment.userApiUrl)
      .subscribe(
        (data) => {
          this.page_loader = false;
          if (data['response'].is_keertan_viewer != 1) {
            this.router.navigate(['404']);
            return false;
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  toggleSidebarFN = () => {
    this.toggleSidebar = !this.toggleSidebar;
  }

  getSearchBatch = () => { 
    this.page_loader = true;
    this.apiService
      .callGetAPI("v2/get-batch", environment.userApiUrl)
      .subscribe(
        (data) => { 
          this.page_loader = false; 
          this.indexBatch = data['response'];  
        },
        (error) => {
          this.page_loader = false;
          this.apiService.failureResponse(error, true);
        }
      ); 
  }

  getSearchMechanism = () => { 
    this.page_loader = true;
    this.apiService
      .callGetAPI("v2/get-mechanism", environment.userApiUrl)
      .subscribe(
        (data) => { 
          this.page_loader = false; 
          this.indexMechanism = data['response'];  
        },
        (error) => {
          this.page_loader = false;
          this.apiService.failureResponse(error, true);
        }
      ); 
  }

  getSearchIndex = (id:any) => { 
    this.page_loader = true;
    this.apiService
      .callGetAPI("v2/get-index/"+id, environment.userApiUrl)
      .subscribe(
        (data) => { 
          this.page_loader = false; 
          this.indexOptions = data['response']; 
          const savedValue = localStorage.getItem('selectedindexValue');
          if (savedValue) {
            this.selectedindexValue = savedValue;
          }else{
            this.selectedindexValue = "basic-kn-index";
          }

        },
        (error) => {
          this.page_loader = false;
          this.apiService.failureResponse(error, true);
        }
      ); 
  }

  
  onMechanismValueChange(value: string){
    // this.indexMechanism.find((id)=>id==value) 
    const found = this.indexMechanism.find(item => item.value === value);
    let id = found ? found.id : null; // Return id if found, otherwise null
    this.getSearchIndex(id)
  }
  onValueChange(value: string) { 
    localStorage.setItem('selectedindexValue', value);
  }

  checkSearchIndexStatus = () => {
    this.page_loader = true;
    this.apiService
      .callGetAPI("v2/static-data/lecture_search_index", environment.userApiUrl)
      .subscribe(
        (data) => { 
          this.page_loader = false;
          if (data['response'] == 1) {
            this.getSearchMechanism()
            this.getSearchIndex(1)
            this.getSearchBatch()
          }
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

  ngOnInit() { 
    this.indexOptions = [];
    this.getPathAccess();
    this.checkSearchIndexStatus();
    this.getHistory();  
    this.safeSourceURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.sourceURL);
  }


  playautio(e:any){

    this.closeVideo()
    this.selectedAudio = e;
    let audioUrl = e.url;
    this.init_audio = true;


    this.playeraudio = videojs('audio-player', {
      controls: true,
      autoplay: false,
      preload: 'auto',
      controlBar: {
        children: [
          'playToggle',        // Play/Pause button
          'currentTimeDisplay', // Current time
          'progressControl',    // Progress bar
          'durationDisplay',    // Total duration
          'volumePanel',        // Volume control
          // Remove 'fullscreenToggle' here
        ],
      },
    });

    this.playeraudio.src({
      src: audioUrl,
      type: 'application/x-mpegURL',
    });

    this.playeraudio.ready(() => {
      console.log('playeraudio is ready');
    });

    this.playeraudio.ready(() => { 
      this.playeraudio.currentTime(e.startTime);   
      this.playeraudio.play();  
    });
   
    this.playeraudio.on('error', () => {
      console.error('Error playing the audio file:', this.playeraudio && this.playeraudio.error());
    });
 
  }


  ngOnDestroy(): void { 

    if (this.playeraudio) {
      this.playeraudio.pause();  
      setTimeout(()=>{
         this.init_audio = false;
      },5) 
    }
  }


 
  jsonToggleSet(e:number){
    if(this.jsonToggle == e)
    {
      this.jsonToggle= null;
    }else{
      this.jsonToggle = e;
    }
  }

      

  toggleSpeechRecognition() {
    if (this.isRecording) {
      this.stopSpeechRecognition();
    } else {
      this.searchQuery = ''; // Reset input
      this.recognition.start();
    }
  }

  stopSpeechRecognition() {
    this.isRecording = false;
    this.recognition.stop();
    this.statusMessage = 'Click the mic to speak';
    setTimeout(() => {
      this.onSubmit()
    }, 500);
  }

  getHistory(){  
    this.apiService
      .callGetAPI("v2/search-history", environment.userApiUrl)
      .subscribe(
        (data) => { 
          this.historys = data['response'];
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }

 
  onSubmit(){ 
    if(this.searchQuery.length < 3)
    { 
      return;
    }
    this.searchText = this.searchQuery;
    this.searchQuery = "";
    setTimeout(() => {
      this.scrollToBottom()
    }, 0);
    this.loader = true;
    this.apiService
      .callGetAPI("v2/search?s="+this.searchText+"&query_index="+this.selectedindexValue+"&mechanism="+this.selectedMechanismValue+"&batch="+this.selectedBatchValue+"&alpha="+this.value, environment.userApiUrl)
      .subscribe(
        (data) => {
          this.loader = false; 
          if(!data['response'])
          {
            this.toasterService.success('', 'failed to receive response please try again later.');
            return;
          }
          this.results.push(data['response'])  
          this.getHistory() 
          this.results.map((obj:any)=>{ 
            obj.search_result.map((ineerObj:any)=>{
              if(ineerObj.asset.audio_title)
              {
                this.audioLists.push(ineerObj.asset) 
              } 
            })
          }) 
          setTimeout(() => {
            this.scrollToBottomSome()
          }, 2000); 
        },
        (error) => {
          console.log('---->error   ', error)
          this.loader = false; 
          this.getHistory() 
          this.toasterService.success('', 'failed to receive response please try again later.');
          this.apiService.failureResponse(error, true);
        }
      );
  }

  getSubmit(e:any){
    this.searchQuery = e;
    setTimeout(() => {
      this.onSubmit()
    }, 200); 
  }

  scrollToBottomSome(): void {
    try {
      // Calculate the new scroll position by adding 400px to the current scroll position
      this.chatContainer.nativeElement.scrollTop += 400;
  
      // If you want to ensure it does not exceed the maximum scroll height, you can do this:
      const maxScrollTop = this.chatContainer.nativeElement.scrollHeight;
      if (this.chatContainer.nativeElement.scrollTop > maxScrollTop) {
        this.chatContainer.nativeElement.scrollTop = maxScrollTop;
      }
    } catch (err) { 
    }
  }
  

  scrollToBottom(): void {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch (err) { 
    }
  }

  hideSideBar(e:any){
    this.sidebar = e
  }

  playSong(i:number) { 
    if (this.checkUser()) {
      if (localStorage.getItem('currentUser')) {
        localStorage.setItem('queueList', JSON.stringify(this.audioLists));
        this.audioService.setData({
          'songList': this.audioLists,
          'selectedindex': i, 
        }); 
        this.audioService.newStateSong('play');
        this.songIndex = i;
      } else {
        this.userService.displayPopup('sign-in');
        return false;
      }
    }
  }






  

  checkUser() {
    if (!localStorage.getItem('currentUser')) {
      this.userService.displayPopup('sign-in');
      return false;
    } else if (localStorage.getItem('currentUser')) {
      const currentUser = this.authService.decryption(localStorage.getItem('currentUser'));
      if (currentUser && currentUser['response'] && currentUser['response'].is_subscribed) {
        return true;
      } else {
        this.router.navigate(['/subscription']);
      }
    }
  }
   
  getIndex():number{
    this.indexAudio = this.indexAudio + 1;
    return this.indexAudio - 1;
  }

  convertTimeToSeconds(timeStr: string): number {
    const [hours, minutes, seconds] = timeStr.split(':');
    const [sec, millis] = seconds.split(',');

    return (
      parseInt(hours, 10) * 3600 +
      parseInt(minutes, 10) * 60 +
      parseInt(sec, 10) +
      parseInt(millis, 10) / 1000
    );
  }


  getPlayedVideo(slug:any){  
    this.ngOnDestroy();
    this.init_video = true;
    this.apiService
      .callGetAPI('v2/watchvideo/' + slug)
      .subscribe(
        (data) => {   
          this.video = data['response']['videos'];
          setTimeout(() => {
            this.decrypt();  
          }, 1); 
        },
        (error) => {
          this.apiService.failureResponse(error, true);
        }
      );
  }




 
playThis(e:any){  
  this.startTime = this.convertTimeToSeconds(e.metadata.start);
  this.endTime = this.convertTimeToSeconds(e.metadata.end);
  this.getPlayedVideo(e.asset.slug)  
}

closeVideo(){ 
  if (this.player) {
    this.player.pause(); // Optionally pause before disposing
    setTimeout(()=>{
      this.init_video = false; 
    },5) 
  }
}

setPlayerConfig() {  
  const getVideo = document.getElementById('videos');
  videojs.Hls.xhr.beforeRequest = options => {
    options.headers = [];
    if (this.video && this.video.is_live === 0) { 
      options.headers['Title'] = this.encrypt(); 
    }
  };  
  this.player = videojs(getVideo, { 
    playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2],
    controls: true,
    preload: 'auto',
    fluid: true,
    autoplay: false,
    seekEventPercentage:  30,
    html5: {
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      nativeTextTracks: false,
      hls: {
        overrideNative: true,
        customDrm: { 
          keys: 
            'MjMyOGMwYWM4MmNhM2ZjNmY4ZDcyNzI0NWQzMDgzYmUxMzU3ZjA0NGZmNWNiNGMzYTExNDJiMDkzY2YyZjNjOTAwNTM0MjE4NTUxMmM5ODlhMWYwMzI3YjQwYmY3YmY1YjE2MDBkMTYzYTA1ZTFkYzE4OWJhMjUwOGM1MTIwODksNDg3MGVjMDY0YzEwODE0MmNjMGZmYmQ2ZmZkZjFlMDhlNzU2YTZkZWYyYzc4MDQ2ODlmZTRhZTBmYzk4NTIyMSxiMmJmNWE5NzAwNGJiMzFkNmY1YTljMmM4NDQ0OTc1OQ'
        }
      }
    },
    tracks: this.setTracks(),
    plugins: {
      hlsQualitySelector: {},
      seekButtons: {
        forward: 10,
        back: 10
      },
      keyboardShortCuts: {},
      spriteThumbnails: {}
    }
  }); 
  this.player.play();
  this.playVideo()
}
 

playVideo(){
  this.player.autoplay(true)
  this.player.src({ 
    src: this.video.hls_playlist_url,
    type: 'application/x-mpegURL'
  }); 

  this.player.ready(() => {  
    this.player.currentTime(this.startTime);   
    this.player.play();  
  });

  this.player.on('timeupdate', () => {
    const currentTime = this.player.currentTime();   
    if (Math.ceil(currentTime) == Math.floor(this.endTime)) {  
      setTimeout(()=>{
        this.pausedVideo()
      },1000)
    }
  }); 
}

pausedVideo(){
  this.player.pause();
}
 
setTracks() {
  if (this.video && this.video.subtitle) {
    if (this.video.subtitle.subtitle_list.length > 0) {
      this.video.subtitle.subtitle_list.forEach(element => {
        this.subTitle.push({
          title: 'subtitles',
          kind: 'subtitles',
          language: element.language,
          label: element.label,
          src: this.video.subtitle.base_url + element.url,
          default: element.default
        });
      });
      this.subTitle.push({});
    } 
    return this.subTitle;
  } else { 
    return [];
  }
}
 
 

decrypt() {
  if (typeof this.video.passphrase !== 'undefined') { 
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Hex.parse(this.video.passphrase)
    });
    this.decrypted = CryptoJS.AES.decrypt(
      cipherParams,
      CryptoJS.enc.Hex.parse(environment.crypto_key),
      {
        keySize: 256,
        iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
        mode: CryptoJS.mode.CBC
      }
    );
    const change = this.decrypted.toString(CryptoJS.enc.Utf8);
    this.plainText = parseInt(this.hexToBinary(change), 10);
    this.incrementInterVal();
  }
}
encrypt() {

  const utf8 = CryptoJS.enc.Utf8.parse('vplayed/' + this.plainText);
  const hex_utf = utf8.toString(CryptoJS.enc.Utf8);
  const hex = this.binaryToHex(hex_utf);
  this.encrypted = CryptoJS.AES.encrypt(
    hex,
    CryptoJS.enc.Hex.parse(environment.crypto_key),
    {
      keySize: 256,
      iv: CryptoJS.enc.Hex.parse(environment.crypto_iv),
      mode: CryptoJS.mode.CBC
    }
  );
  this.cipherText = this.encrypted.ciphertext.toString(CryptoJS.enc.utf8);
  return this.cipherText;
}
incrementInterVal() {
  this.interVal = setInterval(
    function () {
      this.plainText += 1;
    }.bind(this),
    1000
  );
  this.setPlayerConfig();
}

hexToBinary(num) {
  const hex = num,
    bytes = [];
  for (let i = 0; i < hex.length - 1; i += 2) {
    bytes.push(parseInt(hex.substr(i, 2), 16));
  }
  return String.fromCharCode.apply(String, bytes);
}

binaryToHex(num) {
  let str = '',
    i = 0;
  const tmp_len = num.length;
  let c;
  for (; i < tmp_len; i += 1) {
    c = num.charCodeAt(i);
    str += this.d2h(c);
  }
  return str;
  // return parseInt(num, 2).toString(16);
}
d2h(d) {
  return d.toString(16);
}

 

downloadTextFile(textContent:string,slug:string) {
  const blob = new Blob([textContent], { type: 'text/plain' });
  const url = window.URL.createObjectURL(blob);

  // Create an anchor element
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = slug+'.txt';

  // Programmatically click the anchor to trigger download
  anchor.click();

  // Cleanup the object URL
  window.URL.revokeObjectURL(url);
}
 









}
