import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { StoryComponent } from './modules/story/story.component';
import { ShortsComponent } from './modules/shorts/shorts.component';
import { WatchVideoComponent } from './modules/watch-video/watch-video.component';
import { EmbedComponent } from './modules/embed/embed.component';
import { AdminPlaylistComponent } from './modules/admin-playlist/admin-playlist.component';
import { WebseriesDetailComponent } from './modules/category/webseries-detail/webseries-detail.component';
import { SongComponent } from './modules/audio/song/song.component';
import { RefreshComponent } from './modules/refresh/refresh.component';
import { SeeAllStoryComponent } from './modules/see-all-story/see-all-story.component';
import { RadioComponent } from './modules/radio/radio.component';
import { MyAudioplaylistDetailComponent } from './modules/profile/my-audio-playlist/my-audio-playlist-detail/my-audio-playlist-detail.component';
import { AdvisorComponent } from './modules/advisor/advisor.component';
import { UsersDetailComponent } from './modules/users-detail/users-detail.component';
import { SearchComponent } from './modules/search/search.component';

// import { WebseriesDetailComponent } from './modules/category/webseries-detail/webseries-detail.component';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'story',
    component: StoryComponent
  },

  {
    path: 'story/:type',
    component: SeeAllStoryComponent
  },
  {
    path: 'shorts',
    component: ShortsComponent
  },
  {
    path: 'loading',
    component: RefreshComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'act/:token',
    component: HomeComponent
  },
  {
    path: 'watch/:video_id',
    component: WatchVideoComponent
  },
  {
    path: 'series/:slug',
    component: WebseriesDetailComponent
  },
  {
    path: 'embed/:video_id',
    component: EmbedComponent
  },
  {
    path: 'webseries/:seriesslug',
    loadChildren: './modules/series-detail/series-detail.module#SeriesDetailModule'
  },

  {
    path: 'watch/:video_id/:playlist_id/:name',
    component: WatchVideoComponent
  },
  {
    path: 'audio/songs/:song_slug',
    component: SongComponent
    // loadChildren: './modules/audio/song/song.module#SongModule'
  },
  {
    path: 's',
    component: SearchComponent
  },
  {
    path: 'eyJpc3MiOiJodHRwOlwvXC8xMjcuMC4wLjE6O',
    component: AdvisorComponent
  },
  {
    path: 'profile',
    loadChildren: './modules/profile/profile.module#ProfileModule'
  },

  // {
  //   path: 'playlist',
  //   loadChildren: './modules/playlist/playlist.module#PlaylistModule'
  // },
  {
    path: 'category',
    loadChildren: './modules/category/category.module#CategoryModule'
  },
  {
    path: 'radio',
    component: RadioComponent
  },
  // {
  //   path: 'audio/playlist',
  //   loadChildren: './modules/audio-playlist-detail/audio-playlist-detail.module#AudioPlaylistDetailModule',
  // },
  {
    path: 'albums',
    loadChildren: './modules/albums/albums.module#AlbumsModule',
  },
  {
    path: 'album-list',
    loadChildren: './modules/album-list/album-list.module#AlbumListModule',
  },
  {
    path: 'genre',
    loadChildren: '../app/modules/genre/genre.module#GenreModule'
  },
  {
    path: 'video/:slug',
    loadChildren: './modules/video-detail/video-detail.module#VideoDetailModule'
  },
  {
    path: 'story-detail/:slug',
    loadChildren: './modules/video-detail/video-detail.module#VideoDetailModule'
  },
  {
    path: 'video/:slug/:playlist',
    loadChildren: './modules/video-detail/video-detail.module#VideoDetailModule'
  },
  {
    path: 'content/:slug',
    loadChildren: './modules/cms/cms.module#CmsModule'
  },
  {
    path: 'contact-us',
    loadChildren: './modules/contact-us/contact-us.module#ContactUsModule'
  },
  {
    path: 'new-videos',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule'
  },
  {
    path: 'trending-videos',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule'
  },
  {
    path: 'search',
    loadChildren: './modules/search-core/search-core.module#SearchCoreModule'
  },
  {
    path: 'reset-password/:id',
    loadChildren: './modules/reset-password/reset-password.module#ResetPasswordModule'
  },
  {
    path: 'save-playlist',
    loadChildren: './modules/profile/save-playlist/save-playlist.module#SavePlaylistModule'
  },
  {
    path: 'subscription',
    loadChildren: './modules/subscription/subscription.module#SubscriptionModule'
  },
  {
    path: 'live',
    loadChildren: './modules/live-vidoes/live-vidoes.module#LiveVidoesModule'
  },
  {
    path: 'video-not-found',
    loadChildren: './modules/video-not-found/video-not-found.module#VideoNotFoundModule'
  },
  {
    path: 'checkout/:slug',
    loadChildren: './modules/checkout/checkout.module#CheckoutModule'
  },
  {
    path: 'audio-playlist-all',
    loadChildren: './modules/viewall-playlist/viewall-playlist.module#ViewallPlaylistModule'
  },
  {
    path: 'playlist-all',
    component: AdminPlaylistComponent
  },
  {
    path: 'playlist/:slug',
    component: AdminPlaylistComponent
  },
  {
    path: '**',
    loadChildren: './modules/page-not-found/page-not-found.module#PageNotFoundModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
